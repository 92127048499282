// colors
$background-color-body: #ffffff;
$background-white: #ffffff;
$background-black: #000000;
$background-tiffany: #15D4BE;
$background-red: #c40003;
$white: #ffffff;
$black: #000000;
$tiffany: #15D4BE;
$red: #c40003;

// font
$font-family-all: "Jura";