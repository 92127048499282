@use "/src/assets/styles/scss/mixins" as mixins;
@use "/src/assets/styles/scss/variables" as variables;

.projects {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;

    header{
        h1{
            display: none;
        }
    }

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex-grow: 1;

        padding: 0px 60px 45px;

        .AddCardContainer {
            margin: 0 15px 10px;
            align-self: flex-end;
            .closeFormNewProject {
                position: fixed;
                right: 10.5%;
                border: none;
                background: transparent;
                color: variables.$white;
                font-size: x-large;
                cursor: pointer;
                z-index: 100;
                transition: all 0.5s;
                width: 30px;
                font-weight: 700;
                aspect-ratio: 1/1;
            }
            .closeFormNewProject:hover {
                color: #15cfb7;
                transform: scale(1.2);
                border-radius: 15%;
                box-shadow: inset 0px 0px 20px 0px #ff000c,
                    0px 0px 15px 0px #ff000c;
            }
        }
    }
    .onDataMessage {
        display: grid;
        min-width: 25%;
        min-height: 15vh;
        place-items: center;
        align-self: center;
        @include mixins.jura-bold;
        border-radius: 10px;
        box-shadow: inset 0 0 13px red, 0 0 20px 5px black;
        color: white;
        font-size: clamp(18px, 3vw, 50px);
        padding: 10px 20px;
        text-align: center;
        margin: 0 60px;
        background: black;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .projects main {
        padding: 0px 0px 39px;
    }
}

@media only screen and (min-width: 280px) and (max-width: 768px) {
    .projects main {
        padding: 0px 0px 39px;
    }
}