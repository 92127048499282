@use "/src/assets/styles/scss/variables" as variables;
@use "/src/assets/styles/scss/mixins" as mixins;

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 57px 60px;

  .logo-name {
    margin: 10px 20px;
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-name {
      @include mixins.jura-bold;
      font-size: 20px;
      color: variables.$white;
      margin-left: 10px;
      text-decoration: underline variables.$black;
    }

    img {
      margin: 0;
      height: 60px;
    }
  }

  .nav {
    position: absolute;
    display: flex;
    align-items: centers;
    right: 2rem;

    .nav-link {
      color: variables.$white;
      @include mixins.jura-bold;
      font-size: 20px;
      margin-right: 20px;
      text-decoration: none;
      padding: 0.4rem;

      &:hover {
        color: variables.$tiffany;
      }
    }
  }

  .login {
    margin-right: 20px;

    .btn--show-modal {
      color: #000000;
      background-color: #ffffff;
      justify-content: center;
      padding: 9px 0px;
      display: inline-block;
      font-size: 17px;
      cursor: pointer;
      box-shadow: inset 0 0 0 0 #1f1f1f;
      transition: ease-out 0.4s;
      border-radius: 9px;
      border: 1px solid rgb(255, 255, 255);
      text-decoration: none;
      width: 110px;
      font-family: "Jura";
      font-weight: 700;
    }
    .slide-right2:hover {
      box-shadow: inset 400px 0 0 0 #1f1f1f;
    }
    .btn--show-modal:hover {
      color: variables.$white;
      border: 1px solid rgb(255, 255, 255);
    }
  }

  @media only screen and (max-width: 975px) {
    .burger-icon {
      display: block;
      cursor: pointer;
      padding: 0 20px;

      .bar {
        width: 25px;
        height: 3px;
        background-color: variables.$tiffany;
        margin: 6px 0;
        transition: 0.4s;
      }
    }

    .nav {
      display: none;
    }

    .logo-name {
      .header-name {
        display: none;
      }
    }

    &.mobile-menu-open {
      .nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 110px;
        left: 0;
        width: 100%;
        background-color: #333;
        z-index: 1;
        padding: 15px 0px;

        .nav-link {
          margin: 10px;
        }
      }
    }
  }
}

.btn-logout {
  font-size: 1rem;
  padding: 0.4rem;
  border-radius: 15px;
  box-shadow: inset 0 0 0 0 #15d4be;
  transition: ease-out 0.4s;
  cursor: pointer;
}

.btn-logout:hover {
  color: black;
}

.slide-right2:hover {
  box-shadow: inset 400px 0 0 0 #15d4be;
}

//Modal

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  padding: 2rem;
  box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: all 0.5s;
  background-position: center;
  background-size: cover;
}

//Modal

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  padding: 2rem;
  box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: all 0.5s;
  background-position: center;
  background-size: cover;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: all 0.5s;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.navBar .modal .login .btn--close-modal {
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  cursor: pointer;
  border: none;
  background: none;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  background-color: variables.$red;
  border: 1px solid white;
  color: variables.$white;
  border-radius: 5px;
  padding: 2px 6px;
  font-size: 20px;
  @include mixins.jura-medium;
}
