@use "/src/assets/styles/scss/mixins" as mixins;
@use "/src/assets/styles/scss/variables" as variables;

.students {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  
  header{
    h1{
      display: none;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(27 27 27) 100%);
    padding: 0px 70px 39px;
    position: relative;
    flex-grow: 1;
  }
  .onDataMessage {
    display: grid;
    min-width: 25%;
    min-height: 15vh;
    place-items: center;
    align-self: center;
    @include mixins.jura-bold;
    border-radius: 10px;
    box-shadow: inset 0 0 13px red, 0 0 20px 5px black;
    color: white;
    font-size: clamp(18px, 3vw, 50px);
    padding: 10px 20px;
    text-align: center;
    margin: 0 60px;
    background: black;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .students main {
        padding: 0px 0px 39px;
    }
}

@media only screen and (min-width: 280px) and (max-width: 768px) {
    .students main {
        padding: 0px 0px 39px;
    }
}