@use "/src/assets/styles/scss/mixins" as mixins;
.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    gap: 20px;
    padding: 20px 0;
    align-items: stretch;
    & a {
        text-decoration: none;
        flex-shrink: 1;
        display: flex;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .cards {
        gap: 15px;
    }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
    .cards {
        gap: 10px;
    }
}