.formFixedContainer {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    width: 85%;
    height: 77%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    margin: clamp(20px, 5vw, 80px) auto;
    border-radius: 10px 7px 7px 10px;
    box-shadow: 0px 0px 25px 8px black, 0 0 10px 0px #f50000,
        inset 0px 0 10px 0px #15d4be;

    .formRelativeContainer {
        position: relative;
        display: flex;
        margin: 30px 8px 30px 30px;
        overflow: auto;
        height: 100%;
    }
    .formRelativeContainer::-webkit-scrollbar {
        width: 10px;
    }
    .formRelativeContainer::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }
    .formRelativeContainer::-webkit-scrollbar-track {
        margin: -20px;
    }
    .formRelativeContainer::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
