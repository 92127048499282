@use "/src/assets/styles/scss/mixins" as mixins;

.filter {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        flex-direction: row;
        border: 1px solid black;
        border-radius: 10px;
        width: clamp(23px, 8.5rem, 50vw);
        cursor: pointer;

        & p {
            padding: 0 15px 0 0;
            font-size: clamp(23px, 1.8vw, 40px);
            @include mixins.jura-medium;
        }

        & img {
            width: 30px;
            border-radius: 50px;
        }
    }
    & .filter-option {
        z-index: 2;
        border-radius: 15px 0px 0 15px;
        position: absolute;
        width: clamp(50px, 16.5rem, 77vw);
        aspect-ratio: 1/0;
        right: 0;
        top: 110%;
        padding: 10px;
        background: white;

        & > * {
            width: 100%;
            padding: 10px 20px;
            border: 1px solid black;
            border-radius: 50px;
            margin-bottom: 5px;
            cursor: pointer;
            @include mixins.jura-bold;
        }
    }
}
