@use "/src/assets/styles/scss/variables" as variables;
@use "/src/assets/styles/scss/mixins" as mixins;

//Modal

.login-page {
  background-color: white;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.modal {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  padding: 4rem 2rem 2rem 2rem;
  box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: all 0.5s;
  background-image: url("../../assets/images/Login--background.jpg");
  background-position: center;
  background-size: cover;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: all 0.5s;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.btn--close-modal {
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 5px;
  padding: 0 4px;
  font-size: 20px;
  background: transparent;
  color: variables.$white;
  z-index: 100;
  transition: all 0.5s;
  aspect-ratio: 1/1;
  @include mixins.jura-medium;
}
.btn--close-modal:hover {
  color: #15cfb7;
  transform: scale(1.2);
  border-radius: 15%;
  box-shadow:
    inset 0px 0px 20px 0px #ff000c,
    0px 0px 15px 0px #ff000c;
}

.login {
  color: variables.$black;
  display: block;
  text-align: center;
  margin-top: 15rem;
}

.btn--show-modal {
  padding: 1rem;
  background-color: variables.$black;
  color: variables.$white;
  font-size: 1.8rem;
  position: absolute;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(0, 0, 0);
}

.btn--show-modal:hover {
  transform: scale(1.1);
}
