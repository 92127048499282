@use "/src/assets/styles/scss/variables" as variables;
@use "/src/assets/styles/scss/mixins" as mixins;

*{
    @include mixins.jura;
}

body {
    // background: variables.$background-color-body;
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(27 27 27) 100%);
    // min-height: 1000px;
}
