@use "/src/assets/styles/scss/variables" as variables;
@use "/src/assets/styles/scss/mixins" as mixins;

.footer-allpages{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, rgb(0, 0, 0) 37%, rgb(27 27 27) 100%);
  padding: 50px 50px;

  .logo_name {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .name {
      @include mixins.jura-bold;
      font-size: 16px;
      color: variables.$white;
      margin-left: 10px;
      text-decoration: underline variables.$black;
    }

    .logo {
      margin: 0;
      height: 70px;
    }
  }

  .follow {
display: flex;
justify-content: center;
gap: 17px;

      img {
      height: 25px;
      }

    .link {
      color: variables.$white;
      @include mixins.jura-bold;
      font-size: 16px;
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .nav .nav_link:hover {
      color: variables.$white;
    }
  }

  .login {
    margin-right: 20px;

    .login:hover {
      color: variables.$white;
    }

    button {
      display: block;
      margin: 0 auto;
      background-color: variables.$red;
      border: 1px solid white;
      color: variables.$white;
      border-radius: 15px;
      padding: 10px;
      font-size: 15px;
      @include mixins.jura-medium;
    }
  }
}

@media (max-width: 800px) {

  footer {
    .logo_name {
      height: 30px;
      .logo {
    margin: 0;
    height: 45px;
}
    }

.follow{
          .link {

    .text_link{
      display: none;
    }
          }

  }
  }
}

@media (max-width: 1200px) {

  .footer-allpages {
    .logo_name {
      height: 30px;
      .logo {
    margin: 0;
    height: 45px;
}
    }

.follow{
          .link {

    .text_link{
      display: none;
    }
          }

  }
  }
}
