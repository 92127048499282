@use "/src/assets/styles/scss/mixins" as mixins;
@use "/src/assets/styles/scss/variables" as variables;

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(
        118deg,
        rgb(0 0 0) 0%,
        rgb(0, 0, 0) 50%,
        rgb(31 31 31) 100%
    );
    overflow: hidden;
    box-shadow:
        rgb(173 173 173) 20px -3px 22px -29px inset,
        rgba(0, 0, 0, 0.3) 0px 0px 1px 0px inset;
    border-radius: 20px;
    width: clamp(180px, 27vw, 60vw);
    transition: all 0.5s;
    justify-content: space-between;
    background-size: 100% 100%;
    height: 100%;

    .image-container {
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & .base-img {
            object-fit: cover;
            aspect-ratio: 1/1;
            height: clamp(200px, 12vw, 40vw);
        }
        & .student-image {
            height: 100%;
            width: 100%;
            filter: contrast(1.1) brightness(1);
        }
        & .card-image {
            width: 100%;
            aspect-ratio: 5/2;
            height: 240px;
        }
        & .default-img {
            aspect-ratio: 1/1;
        }
    }

    & .card-content {
        display: flex;
        flex-direction: column;
        padding: 30px 10px;
        align-items: center;
        justify-content: space-around;
        flex-grow: 1;
        & button {
            background: transparent;
            width: 100%;
            border: none;
            display: flex;
            flex: 1 1 0;
            cursor: pointer;
            & .card-title {
                color: variables.$tiffany;
                @include mixins.jura-bold;
                transition: all 0.5s;
                width: max-content;
                padding-bottom: 12px;
                inline-size: fit-content;
                flex: 1 1 0;

                font-size: 20px;
            }
        }
        & .card-description {
            color: variables.$white;
            @include mixins.jura;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            padding-bottom: 20px;
            font-size: 17px;
        }
        .card-description-button {
            color: variables.$white;
            justify-content: center;
            padding: 9px 40px;
            display: inline-block;
            font-size: 15px;
            cursor: pointer;
            box-shadow: inset 0 0 0 0 #000000;
            transition: ease-out 0.4s;
            letter-spacing: 2px;
            border-radius: 20px;
            border: 1px solid rgb(255 255 255);
            text-decoration: none;
            width: 210px;
            font-family: "Jura";
            font-weight: 700;
        }
        .slide-right2:hover {
            box-shadow: inset 400px 0 0 0 #ffffff;
        }
        .card-description-button:hover {
            color: rgb(0, 0, 0);
            border: 1px solid rgb(255, 255, 255);
        }
    }
}
.card:hover {
    cursor: pointer;
    transform: scale(1.05);
}

@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .card {
        width: clamp(180px, 30vw, 60vw);
    }
    .card .card-content button .card-title {
        font-size: 18px;
    }
    .card .card-content .card-description {
        font-size: 14px;
    }
    .card .card-content .card-description-button {
        padding: 9px 10px;
        font-size: 13px;
        width: 151px;
        letter-spacing: 0px;
    }
    .navbar {
        padding: 58px 30px;
    }
    .footer-allpages .follow {
        gap: 10px;
    }
    .LoadMoreButton:hover button p {
        font-size: 18px;
    }
    .filter {
        padding-right: 35px;
    }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
    .card {
        width: clamp(195px, 41vw, 45vw);
    }
    .navbar {
        padding: 41px 10px;
    }
    .card .card-content button .card-title {
        font-size: 16px;
    }
    .card .card-content .card-description {
        font-size: 13px;
    }
    .card .card-content .card-description-button {
        font-size: 13px;
        letter-spacing: 0px;
        width: 145px;
    }
    .footer-allpages {
        padding: 50px 10px;
    }
    .filter {
        padding-right: 35px;
    }
    .footer-allpages {
        padding: 50px 0px;
        flex-direction: column;
        gap: 45px;
    }
    .footer-allpages .logo_name .logo {
        height: 50px;
    }
    .footer-allpages .follow .link {
        margin-right: 0px;
    }
    .footer-allpages .follow img {
        margin-right: 0px;
    }
}

@media only screen and (min-width: 280px) and (max-width: 426px) {
    .card {
        width: clamp(265px, 80vw, 80vw);
    }
    .navbar {
        padding: 35px 0px;
    }
    .footer-allpages {
        padding: 50px 0px;
        flex-direction: column;
        gap: 45px;
    }
    .footer-allpages .logo_name .logo {
        height: 50px;
    }
    .footer-allpages .follow .link {
        margin-right: 0px;
    }
    .footer-allpages .follow img {
        margin-right: 0px;
    }
    .card .card-content .card-description-button {
        width: 170px;
    }
    .filter {
        padding-right: 35px;
    }
}
