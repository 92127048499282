code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.signup--container {
  max-width: 60rem;
  background-color: #ffffff;
  padding: 2rem 5rem;
}

.heading {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 4.5rem;
  line-height: 1.5;
}

.signup--infomation {
  text-align: center;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  color: #ffffff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: "Jura", sans-serif;
}

.mb-3 {
  margin-bottom: 2rem;
}

.form-label {
  margin-right: 2rem;
  display: inline-block;
  width: 15rem;
}

.form-control {
  font-size: 1.7rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}

.form-text {
  font-size: 1rem;
  margin-left: 18rem;
  margin-top: 0.4rem;
}
.form-check {
  display: flex;
  align-items: center;
  margin-left: 21rem;
}

#password {
  margin-bottom: 0.4rem;
}

.form-check-input {
  width: 3rem;
  height: 1.4rem;
}

.form-check-label {
  font-size: 1.2rem;
}

.btn-send {
  padding: 0.5rem;
  width: 8rem;
  align-self: center;
  font-size: 1.6rem;
  border-radius: 10px;
  background-color: #28a745;
  color: #fff;
}

.btn-send:hover {
  filter: brightness(80%);
}

@media only screen and (max-width: 1304px) {
  .form-check {
    margin: auto;
  }
  .btn-send {
    margin-top: 2rem;
  }
}
