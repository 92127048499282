@use "/src/assets/styles/scss/mixins" as mixins;
@use "/src/assets/styles/scss/variables" as variables;

.newProjectForm {
    color: #b300ff;
    color: white;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 98%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;

    & > section {
        max-width: calc(50% - 20px);
        width: calc(50% - 20px);
    }
    h2 {
        font-size: clamp(18px, 5vw, 24px);
        margin-bottom: 10px;
    }
    button {
        background-color: #10c7af;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        font-weight: 700;
    }

    section {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        label {
            display: block;
            margin-bottom: 10px;

            input,
            textarea,
            select {
                width: 100%;
                padding: 8px;
                border-radius: 4px;
                font-size: 16px;
                background: transparent;
                color: white;
                border: 0;
                border-bottom: 1px solid white;
                max-width: 100%;

                &:focus {
                    outline: none;
                    color: #12cab2;
                    border-bottom: 1px solid #12cab2;
                    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
                }
            }

            .input_date_have_been_done {
                color: white;
                font-size: 1rem;

                &::-webkit-datetime-edit-text {
                    padding: 0 1rem;
                }

                &::-webkit-datetime-edit-month-field {
                    text-transform: uppercase;
                }

                &::-webkit-datetime-edit-day-field {
                    text-transform: uppercase;
                }

                &::-webkit-datetime-edit-year-field {
                    text-transform: uppercase;
                }

                &::-webkit-inner-spin-button {
                    display: none;
                }

                &::-webkit-calendar-picker-indicator {
                    border-radius: 5px;
                    background-color: white;
                }

                &:focus {
                    color: #12cab2;

                    &::-webkit-calendar-picker-indicator {
                        background-color: #12cab2;
                    }
                }
            }

            button {
                margin-left: 10px;
                cursor: pointer;
                background-color: #dc3545;
                color: #fff;
                border: none;
                padding: 5px 10px;
                border-radius: 4px;
                height: 86%;
                aspect-ratio: 1/1;

                &:hover {
                    background-color: #c82333;
                }
            }
        }
        .team_members_names_section,
        .instructors_names_section,
        .repositories_links_section {
            label {
                position: relative;
                > input {
                    padding-right: 40px;
                }
                > button {
                    position: absolute;
                    margin: 0;
                    top: 2px;
                    right: 2px;
                }
            }
        }

        .preview-image {
            display: flex;
            flex-direction: column;
            color: red;
            font-weight: 700;
            font-size: large;

            img {
                max-width: 100%;
                max-height: 250px;
                margin: 10px;
                align-self: center;
                box-shadow: 0 0 15px rgba(18, 202, 178, 0.1411764706);
            }
        }
    }

    input[type="submit"] {
        cursor: pointer;
        background-color: #28a745;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        font-size: 18px;
        margin-top: 20px;
        width: 70%;

        &:hover {
            background-color: #218838;
        }
    }

    // styling react selector
    .css-1nmdiq5-menu {
        background: black;
        box-shadow: inset 0px 0px 10px red;
    }
    .css-d7l1ni-option {
        background: #12cab240;
        margin: 10px;
        max-width: 100%;
        width: auto;
    }
    .css-d7l1ni-option:active {
        background-color: #323232;
    }
    .css-10wo9uf-option {
        margin: 10px;
        max-width: 100%;
        width: auto;
    }
    .css-10wo9uf-option:active {
        background-color: #001836;
    }
    
    .css-16xfy0z-control {
        background: transparent;
    }
    .css-13cymwt-control {
        background: #ffffff45;
        .css-1jqq78o-placeholder {
            color: white;
        }
    }
    .css-t3ipsp-control {
        background: transparent;
    }
    .css-166bipr-Input {
        color: #12cab2;
    }
    .css-1dimb5e-singleValue {
        color: #12cab2;
        color: white;
    }
    .css-wsp0cs-MultiValueGeneric {
        background: white;
        color: black;
        font-weight: 700;
        width: calc;
    }
    .css-12a83d4-MultiValueRemove {
        background: #dc3545;
    }
    .css-12a83d4-MultiValueRemove:hover {
        background: #ffbdad;
        svg {
            background: transparent;
        }
    }
    .css-1p3m7a8-multiValue {
        box-shadow: 0 0 5px red;
    }
    // end of styling react selector
}
@media (max-width: 678px) {
    .newProjectForm {
        flex-direction: column;
        align-items: center;
        & > section {
            max-width: 100%;
            width: 100%;
        }
    }
}
